import React from 'react'
import {Grid, Typography, Box, Button, TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import request from '../services/request'
import {API_ROOT, API_CLAIM} from '../config/config' 
import Alert from '@material-ui/lab/Alert'

class PartnerEndorsement extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            cBands: null,
            endorsee: null,
            selectedPartner: null,
            submitting: false
        }

        this.handleEndorseeSelection = this.handleEndorseeSelection.bind(this)
        this.handleSubmission = this.handleSubmission.bind(this)
        this.completeSubmission = this.completeSubmission.bind(this)
    }
    
    loadCBandList() {
        request(API_ROOT + "/api/GetEndorsementUser", API_CLAIM)
        .then(res => res.json())
        .then((data) => {
            this.setState({
                cBands: data
            })
    
        })
    }

    componentDidMount() {
        this.loadCBandList()
    }

    componentDidUpdate() {
        if (this.state.selectedPartner !== this.props.selectedPartner) {
            this.setState({
                selectedPartner: this.props.selectedPartner,
                endorsee: null
            })
        }
    }

    handleEndorseeSelection(event, value) {
        this.setState({
            endorsee: value
        })
    }

    completeSubmission() {
        this.setState({
            submitting: false
        })
    }

    handleSubmission() {
        this.setState({
            submitting: true
        }, () => {
            this.props.onFormSubmission(this.state.endorsee, this.completeSubmission)
        })
    }

    cBandEntry(user) {
        return {
            name: user.email,
            email: user.email,
            flag: "C-Band"
          }
    }

    render() {
        // Is the button enabled?
        const  disabledSubmission = this.state.submitting
        || !this.state.endorsee
        || !this.props.selectedPartner
        || !(this.props.validationStatus.tenantExists === "true")
        || !this.props.trustedPartnerStatus.map((status)=>status.checked).reduce((accumulator, value) => accumulator & value)
        
        const disabledEndoresee = !this.props.selectedPartner

      var selectionList = []

      if (this.state.cBands) {
          selectionList = selectionList.concat(this.state.cBands.map((user) => this.cBandEntry(user)))
      }

      return(
        <Grid container direction="row" justify="space-around">
            <Grid item xs={12}>
                <Typography variant="h6">
                    Endorsement
                </Typography>
                <Typography variant="caption">
                    A C-Band employee has to endorse the registration of the external company for collaboration with Swiss Re. This person must confirm that:
                    <ul>
                        <li>A contract or a non-disclosure agreement (NDA) with the external company is in place</li>
                        <li>The Partner Company Name is correct and in line with existing contract/NDA</li>
                        <li>This Partner Company is suitable for collaborating with Swiss Re using Microsoft Teams, OneDrive, and other Microsoft Azure Cloud services</li>
                    </ul>
                </Typography>
            </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box>
                            Registration endorsed by:
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Box paddingLeft={2} marginBottom={2}>
                                
                            <Autocomplete
                                options={selectionList}
                                value={this.state.endorsee ? this.state.endorsee : {name: ""}}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option) => option.name}
                                style={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select Endorser" variant="outlined" />}
                                disabled={disabledEndoresee}
                                onChange={this.handleEndorseeSelection}
                            >
                            </Autocomplete>
                        </Box>                            
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} align="right">
                        <Button variant="contained" color="primary" onClick={this.handleSubmission} disabled={disabledSubmission}>Submit</Button>
                        {this.props.alertText &&
                        <Alert severity="error">{this.props.alertText}</Alert>
                        }
                    </Grid>
            </Grid>
        );
      }
  }

  export default PartnerEndorsement