import React from 'react'
import request from '../services/request'
import {authProvider}  from '../services/authProvider'
import { API_ROOT, API_CLAIM } from '../config/config'
import Alert from '@material-ui/lab/Alert'
import {Grid, Typography, Table, TableBody, TableRow, TableCell, Button} from '@material-ui/core'

class Endorsement extends React.Component {
    constructor(props) {
        super(props)

        this.params = props.match.params

        this.state = {
          registrationDetails: null,
          enabled: false,
          cBands: null,
          partnerManager: null,
          partner: null,
          alertType: "none",
          alertText: ""
        }

        this.getAdditionalUserInformation = this.getAdditionalUserInformation.bind(this)
        this.loadCBandList = this.loadCBandList.bind(this)
        this.checkUserEntitlement = this.checkUserEntitlement.bind(this)
        this.loadPartner = this.loadPartner.bind(this)
        this.handleSubmission = this.handleSubmission.bind(this)
    }
    
    componentDidMount() {
      if (!this.params.id) {
        this.changeAlertText("error",
          "There was an error retrieving the partner domain registration details. Please check the link and if the issue continues, raise a Contact One support ticket"
        )
      } else {
        this.getPartnerRegistrationDomainDetails()
      }
    }

    loadCBandList() {
      request(API_ROOT + "/api/GetEndorsementUser", API_CLAIM)
      .then(res => res.json())
      .then((data) => {
          this.setState({
              cBands: data
          }, this.loadPartner)
      })
    } 

    loadPartner() {
      request(API_ROOT + '/api/GetPartner?mdmPartnerID=' + encodeURI(this.state.registrationDetails.mdmPartnerID), API_CLAIM)
      .then(res => res.json())
      .then((data) => {
          this.setState({
              partner: data[0]
          }, this.checkUserEntitlement)            
      })

    }

    formatDate(dateString) {
      var date = new Date(dateString)
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear() + "  " + strTime;
    }

    checkUserEntitlement() {
      const account = authProvider.getAccountInfo().account.userName.toLowerCase()
      const accountEmail = account.toLowerCase()

      var endorsed = this.state.partnerManager != null && accountEmail === this.state.partnerManager.partnerManagerEmail ? this.state.partnerManager.partnerManagerEmail.toLowerCase() : ""

      if (!endorsed) {
        if (this.state.cBands.find((cband) => cband.email.toLowerCase() === accountEmail)) {
          endorsed = true
        }
      }

      var alertText = ""
      var alertType = "none"

      if (this.state.registrationDetails.endorsed) {
        alertType = "info"
        alertText = "This domain registration has already been endorsed"
      }
      else if (!endorsed) {
        alertText = "You don't have the necessary rights to endorse this request. Only C-Bands and higher are able to endorse a partner domain registration."
        alertType = "warning"
      }

      this.setState({
        enabled: endorsed,
        alertText: alertText,
        alertType: alertType
      })
    }

    changeAlertText(status, text) {
      this.setState({
          alertText: text,
          alertType: status
      })
    }

    handleSubmission() {
      const account = authProvider.getAccountInfo().account.userName.toLowerCase()

      var payload = {
        PartnerDomainRegistrationID: this.state.registrationDetails.rowKey,
        RequestorEmail: account,
        PartnerName: this.state.partner.partnerName
      }

      request(API_ROOT + "/api/EndorsePartnerDomainRegistration", API_CLAIM, payload)
      .then((res) => {
          if (res.status === 200) {
              // Show results and disable form
              const registrationDetails = Object.assign({}, this.state.registrationDetails, {endorsed: true})
              this.setState({
                 enabled: false,
                 registrationDetails: registrationDetails
              })
              this.changeAlertText("success", "Endorsement is Complete. You can close this window")
          } else {
              this.changeAlertText("error", res.statusText)
          }
      })
    }

    getAdditionalUserInformation() {
      // Get the cbands and the partner details to validate the user against these entries to asses if they can endorse this registration
      request(API_ROOT + '/api/GetPartnerManager?mdmPartnerID=' + encodeURI(this.state.registrationDetails.mdmPartnerID), API_CLAIM)
      .then(res => res.json())
      .then((data) => {
          this.setState({
              partnerManager: data[0]
          }, this.loadCBandList)            
      })
    }

    getPartnerRegistrationDomainDetails() {
      request(API_ROOT + '/api/GetPartnerDomainRegistrationDetails?PartnerDomainRegistrationID=' + encodeURIComponent(this.params.id), API_CLAIM)
      .then(res => res.json())
      .then((data) => {
          this.setState({
              registrationDetails: data
          }, this.getAdditionalUserInformation)            
      })
      .catch(() => {
        this.setState({
          alertType: "error",
          alertText: "There was an error retrieving the domain request. Please try again or raise a Contact One if the issue persists"
        })
      })
    }

    render() {
      const disabledSubmission = !this.state.registrationDetails || this.state.registrationDetails.endorsed || !this.state.enabled

      return(
        <Grid container direction="column" justify="space-around" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">
            Partner Domain Registration Request
            </Typography>
          </Grid>
          {this.state.alertType !== "none" && <Grid item xs={12}>
            <Alert severity={this.state.alertType}>{this.state.alertText}</Alert>
          </Grid>}

          <Grid item xs={12}>
           <Typography>
            On this page you can endorse the partner domain registration request. 
            <br/><br/>
            Please review the request detailed below and endorse the registration using the button.
            <br/><br/>
            By endorsing this request, you confirm the following:
            <ul>
              <li>A contract or a non-disclosure agreement (NDA) with the external company is in place</li>
              <li>The Partner Company Name displayed below is correct and in line with existing contract/NDA</li>
              <li>This Partner Company is suitable for collaborating with Swiss Re using Microsoft Teams, OneDrive, and other Microsoft Azure Cloud services</li>
            </ul>
            Once endorsed, the domain registration will be processed as soon as possible. It usually takes up to two business days. In rare cases, it may take a bit longer.
            <br/>
            As soon as the processing is complete, Swiss Re employees will be able to onboard external users with e-mail addresses ending in
            <strong> {this.state.registrationDetails? this.state.registrationDetails.domain : ""} </strong>
            using the <a href="https://eampr.swissreapps.com/plugins/pluginPage.jsf?pn=CustomerManagement" target="_new">External User Onboarding Tool</a>.
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                    Request Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Request Status</TableCell>
                    <TableCell>{this.state.registrationDetails && this.state.registrationDetails.endorsed ? "Completed" : "Pending"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date Requested</TableCell>
                    <TableCell>{this.state.registrationDetails? this.formatDate(this.state.registrationDetails.timestamp) : ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Requestor</TableCell>
                    <TableCell>{this.state.registrationDetails? this.state.registrationDetails.requestorEmail : ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Partner ID</TableCell>
                    <TableCell>{this.state.partner? this.state.partner.mdmPartnerID : ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Partner Company Name</TableCell>
                    <TableCell>{this.state.partner? this.state.partner.partnerName : ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Endorsement Requested to</TableCell>
                    <TableCell>{this.state.registrationDetails? this.state.registrationDetails.endorsementContactEmail : ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Domain Requested</TableCell>
                    <TableCell>{this.state.registrationDetails && this.state.registrationDetails.domain}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item cs={12} align="center">
               <Button variant="contained" color="primary" onClick={this.handleSubmission} disabled={disabledSubmission}>Endorse Request</Button>
            </Grid>
        </Grid>
        );
      }
  }

  export default Endorsement
