import { API_CLAIM } from '../config/config'
import {authProvider} from './authProvider'

const request = async (url, scope = API_CLAIM, payload) => {
    const token = await authProvider.getAccessToken({scopes: [scope]});
    if (payload) {
        return fetch(url, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token.accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
    } else {
        return fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token.accessToken,
                'Content-Type': 'application/json',
            },
        });
    }
};

export default request
