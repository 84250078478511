// Return true when INVALID
function validateDomain(domain, domainList)  {
    if (domain) {
        const index = domain.lastIndexOf('.')
        if (index <= 0 || index >= domain.length -2) {
            return true
        }

        // Don't validate an already validated domain
        if (domainList) {
            return domainList.findIndex((element) => element.domain === domain) > -1
        } else {
            return false
        }
    } else {
        return true
    }
}

export default validateDomain