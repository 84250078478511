const local = {
    REDIRECT_URI: "http://localhost:3000",
    TENANT_ID: "45597f60-6e37-4be7-acfb-4c9e23b261ea",
    APP_CLIENT_ID: "8acf9a24-7652-4b2f-afa5-303051cd4144",
    API_CLAIM: "api://731ec200-ff6e-44ee-aa31-fbd31b1207b6/.default",
    API_ROOT: "https://pedrt.api-dev.swissre.com"
};

const dev = Object.assign({}, local, {
    REDIRECT_URI: window.location.origin,
    TENANT_ID: "45597f60-6e37-4be7-acfb-4c9e23b261ea",
    APP_CLIENT_ID: "8acf9a24-7652-4b2f-afa5-303051cd4144",
    API_CLAIM: "api://731ec200-ff6e-44ee-aa31-fbd31b1207b6/.default",
    API_ROOT: "https://pedrt.api-dev.swissre.com"
});

const nonprod = {
    REDIRECT_URI: window.location.origin,
    TENANT_ID: "45597f60-6e37-4be7-acfb-4c9e23b261ea",
    APP_CLIENT_ID: "5d577cd8-2a9e-43da-b7d2-348504faec23",
    API_CLAIM: "api://d9420019-20d0-4978-be78-dffb37f96ba9/.default",
    API_ROOT: "https://pedrt.api-np.swissre.com"
};

const prod = {
    REDIRECT_URI: window.location.origin,
    TENANT_ID: "45597f60-6e37-4be7-acfb-4c9e23b261ea",
    APP_CLIENT_ID: "28fe7320-b435-464e-89cf-218139eeb496",
    API_CLAIM: "api://9b86596a-fde9-4ee3-91be-660b92f12ba8/accessprd",
    API_ROOT: "https://pedrt.api.swissre.com"
};

let config = local;

const devUrls = [
    "https://iamaz-dev-slz-pedrt-app.azurewebsites.net",
    "https://iamaz-dev-pedrt-app.azurewebsites.net",
    "https://partnermanagement.swissredev.com",
];
if (devUrls.includes(window.location.origin)) {
    config = dev
}

const nonProdUrls = [
    "https://iamaz-npd-slz-pedrt-app.azurewebsites.net",
    "https://iamaz-npd-pedrt-app.azurewebsites.net",
    "https://partnermanagement.swissrenonprod.com",
];
if (nonProdUrls.includes(window.location.origin)) {
    config = nonprod
}

const prodUrls = [
    "https://iamaz-prd-slz-pedrt-app.azurewebsites.net",
    "https://iamaz-prd-pedrt-app.azurewebsites.net",
    "https://partnermanagement.swissre.com",
];
if (prodUrls.includes(window.location.origin)) {
    config = prod
}

module.exports = config;
