import React from 'react'
import {Grid, Typography, Box, Paper, InputBase} from '@material-ui/core'
import domainCheck from '../data/domainCheck.json'
import {API_ROOT, API_CLAIM } from '../config/config'
import request  from '../services/request'
import validateDomain from '../services/validateDomain'

class PartnerDomainEditor extends React.Component {

    constructor(props) {
        super(props)

        this.handleDomainChange = this.handleDomainChange.bind(this)
        this.handleValidation = this.handleValidation.bind(this)

        this.state = {
            disableButton: this.checkDisableButton(this.props.validatedDomain)
        }
    }

    handleDomainChange(e) {
        var domain = e.target.value
        this.props.onDomainChange(domain, () => {
            this.setState({
                disableButton: this.checkDisableButton(domain)
            })
        })
    }

    handleValidation(e) {
        // Validate here, show progress
        this.props.onValidationChange({tenantExists: "checking"}, () => {
            // After validation
            this.disableButton = this.checkDisableButton(this.props.validatedDomain)
            
            request(API_ROOT + '/api/GetTenantID?tenantDomain=' + this.props.validatedDomain, API_CLAIM)
            .then(res => res.json())
            .then((data) => {
                this.props.onValidationChange(domainCheck, () => {
                    this.setState({
                        disableButton: this.checkDisableButton(this.props.validatedDomain)
                    })
                })    
            })
        })
    }

    checkDisableButton(domain) {
        if (this.props.validationStatus.tenantExists !== "unknown") return true

        return validateDomain(domain)
    }

    validationText() {
        switch(this.props.validationStatus.tenantExists) {
            case "unknown":
                return "Not validated"
            case "checking":
                return "Validating..."
            case "true":
                return "Validated"
            default:
                return "Invalid"
        }
    }

    render() {
      return(
        <Box paddingTop={1}>
            <Grid container direction="row" justify="space-around" spacing={0}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Add Email Domain
                    </Typography>
                    <Typography variant="caption">
                        The email domain is the part of the email address after the @ sign. Example: The email domain of John_Doe@example.com is ‘example.com’
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box paddingTop={1} paddingBottom={1}>
                        <Paper className="searchBorder" elevation={1}>
                            <InputBase
                            className="searchInputNarrow"
                            onChange={this.handleDomainChange}
                            value={this.props.validatedDomain}>
                            </InputBase>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        )
      }
  }

  export default PartnerDomainEditor