import React from 'react'
import {Box, Typography, ListItem, ListItemText, Paper} from '@material-ui/core'
import { FixedSizeList } from 'react-window'
import {Redirect} from 'react-router-dom'

class PartnerDomainList extends React.Component {
    constructor(props) {
        super(props)

        this.handleDomainClick = this.handleDomainClick.bind(this)
        this.renderRow = this.renderRow.bind(this)

        this.state = {
            openDomainRegistration: null
        }
    }

    handleDomainClick(domainRegistration) {
        if (!domainRegistration.noClick) {
            this.setState({
                openDomainRegistration: domainRegistration.rowKey
            })
        }
    }
    
    renderRow(props) {
        const { data, index, style } = props;

        return (
            <ListItem button={!data[index].endorsed} style={style} key={data[index].domain} onClick={() => this.handleDomainClick(data[index])}>
                <ListItemText
                    primary={<span>{data[index].endorsed && data[index].domain} {!data[index].endorsed && <i>{data[index].domain}</i>}</span>}
                    secondary={<span>{!data[index].endorsed && <Typography variant="caption">Endorsement pending</Typography>}</span>}
                    >
                </ListItemText>
            </ListItem> 
        );
      }

    render() {
        var noDomains = !this.props.partnerDomainList || this.props.partnerDomainList.length === 0
        var emptyDomainList

        if (this.props.partnerDomainList && this.props.partnerDomainList.length === 0) {
            emptyDomainList = [{
                domain: "No domains are registered",
                endorsed: true,
                noClick: true
            }]
        } else {
            emptyDomainList = []
        }

      return(
        <Box className="partnerDomainListBox">
            {this.state.openDomainRegistration && <Redirect push to={"/endorse/" + encodeURI(this.state.openDomainRegistration)}></Redirect>}
            <Typography variant="h6" noWrap color={this.props.partnerDomainList && this.props.partnerDomainList.length > 0 ? 'inherit' : 'secondary'} >
            Registered Domains
            </Typography>
            <Box paddingTop={1} paddingBottom={1}>
                <Paper className="partnerDomainListPaper">
                    <FixedSizeList
                        disabled={noDomains}
                        itemData={noDomains ? emptyDomainList : this.props.partnerDomainList}
                        height={540}
                        itemSize={48}
                        itemCount={noDomains ? emptyDomainList.length: this.props.partnerDomainList.length}>
                        {this.renderRow}
                    </FixedSizeList>
                </Paper>
            </Box>
        </Box>
    );
      }
  }

  export default PartnerDomainList