import React from 'react'
import {Grid} from '@material-ui/core'
import PartnerList from './PartnerList'
import PartnerDomainList from './PartnerDomainList'
import PartnerDomainEditor from './PartnerDomainEditor'
import PartnerTrustDeclaration from './PartnerTrustDeclaration'
import PartnerEndorsement from './PartnerEndorsement'
import request from '../services/request'
import {authProvider}  from '../services/authProvider'
import { API_ROOT, API_CLAIM, REDIRECT_URI } from '../config/config'
import validateDomain from '../services/validateDomain'

const trustedPartnerStatuses = [
    {name: "I confirm that a contract and/or a Non-Disclosure-Agreement (NDA) between Swiss Re and the external company exists", checked: false}
]

class PartnerManagement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            partnerList: null,
            partnerDomainList: null,
            selectedPartner: null,
            selectedPartnerManager: null,
            validatedDomain: '',
            validationStatus: {tenantExists: "unknown"},
            trustedPartnerStatus: trustedPartnerStatuses,
            alertText: null
        }

        this.handlePartnerSelection = this.handlePartnerSelection.bind(this)
        this.handleDomainChange = this.handleDomainChange.bind(this)
        this.handleValidationChange = this.handleValidationChange.bind(this)
        this.handleTrustedPartnerStatus = this.handleTrustedPartnerStatus.bind(this)
        this.handleFormSubmission = this.handleFormSubmission.bind(this)
        this.changeAlertText = this.changeAlertText.bind(this)
        this.loadPartnerLocations = this.loadPartnerLocations.bind(this)
        this.loadPartnerLocation = this.loadPartnerLocation.bind(this)
    }
    
    changeAlertText(text) {
        this.setState({
            alertText: text
        })
    }

    handlePartnerSelection(selectedPartner) {
        this.setState({
          selectedPartner: selectedPartner,
          selectedPartnerManagaer: null,
          partnerDomainList: null
        }, () => {
            this.loadPartnerManager(selectedPartner, () => {
                this.loadPartnerDomainList(selectedPartner)
            })
        })
      }

      loadPartnerManager(selectedPartner, func) {
        request(API_ROOT + "/api/GetPartnerManager?MDMPartnerID=" + selectedPartner.mdmPartnerID, API_CLAIM)
        .then(res => res.json())
        .then((data) => {
            this.setState({
                selectedPartnerManager: data[0]
            }, func)
        })

      }

      handleDomainChange(domain, func) {
        var status
        if (validateDomain(domain, this.state.partnerDomainList)) {
            status = {tenantExists: "false"}
        } else {
            status = {tenantExists: "true"}
        }

        this.setState({
            validatedDomain: domain,
            validationStatus: status

        }, func)
      }

      handleValidationChange(status, func) {
          this.setState({
              validationStatus: status
          }, func)
      }

      handleFormSubmission(endorsee, callback) {
        // Submit request
        if (this.state.selectedPartner && endorsee && this.state.validatedDomain) {
            const account = authProvider.getAccountInfo().account.userName.toLowerCase()

            const endorsed = account === endorsee.email.toLowerCase()

            const submission = {
                MDMPartnerID: this.state.selectedPartner.mdmPartnerID,
                Domain: this.state.validatedDomain,
                RequestorEmail: account,
                EndorsementType: endorsee.flag,
                EndorsementContactEmail: endorsee.email,
                Endorsed: endorsed,
                PartnerName: this.state.selectedPartner.partnerName,
                EndorsementURL: REDIRECT_URI + "/endorse/" + this.state.selectedPartner.mdmPartnerID + "_" + this.state.validatedDomain
            }

            request(API_ROOT + "/api/AddPartnerDomainRegistration", API_CLAIM, submission)
            .then((res) => {
                if (res.status === 200) {
                    // Clear all the form elements here.
                    this.setState({
                        validatedDomain: '',
                        validationStatus: {tenantExists: "unknown"},
                        trustedPartnerStatus: trustedPartnerStatuses,
                        partnerDomainList: [...this.state.partnerDomainList, {
                            "MDMPartnerID": this.state.selectedPartner.mdmPartnerID,
                            "domain": this.state.validatedDomain,
                            "requestorEmail": account,
                            "endorsed": endorsed,
                            "rowKey": this.state.selectedPartner.mdmPartnerID + "_" + this.state.validatedDomain
                        }],
                        alertText: null
                    }, callback())
                } else {
                    this.changeAlertText(res.statusText)
                    callback()
                }
            })
        }
      }

      handleTrustedPartnerStatus(trustedPartnerStatus) {
          this.setState({
              trustedPartnerStatus: trustedPartnerStatus
          })
      }

    loadPartnerList() {
        request(API_ROOT + '/api/GetPartner', API_CLAIM)
        .then(res => res.json())
        .then((data) => {
            this.setState({
                partnerList: data
            }, this.loadPartnerLocations)            
        })
    }

    loadPartnerLocation(selectedPartner) {
        if (!selectedPartner.locationLoaded) {
            const index = this.state.partnerList.indexOf(selectedPartner)
            
            // Make sure we only try and load the location once.
            this.setState({
                partnerList:[
                    ...this.state.partnerList.slice(0, index),
                    Object.assign({}, this.state.partnerList[index], {locationLoaded: true}),
                    ...this.state.partnerList.slice(index + 1)
                ]
            }, () => {
                request(API_ROOT + '/api/GetPartnerLocation?mdmPartnerID=' + encodeURI(selectedPartner.mdmPartnerID), API_CLAIM)
                .then(res => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        this.setState({
                            partnerList:[
                                ...this.state.partnerList.slice(0, index),
                                Object.assign({}, this.state.partnerList[index], {location: data[0].location}),
                                ...this.state.partnerList.slice(index + 1)
                            ]
                        })
                    }
                })
            })
        }
    }

    loadPartnerLocations() {
        /* We will only support partner locations in a later release as performance needs to be improved

        request(API_ROOT + '/api/GetPartnerLocation', API_CLAIM)
        .then(res => res.json())
        .then((data) => {
            var partners = this.state.partnerList.map((partner) => {
                var location = data.find((location) => location.mdmPartnerID === partner.mdmPartnerID)
                if (location) {
                    return Object.assign({}, partner, {"location" : location.location})
                } else {
                    return partner
                }
            })

            this.setState({
                partnerList: partners
            })
        })
        */
    }

    loadPartnerDomainList(selectedPartner) {
        request(API_ROOT + "/api/GetPartnerDomainRegistration?MDMPartnerID=" + selectedPartner.mdmPartnerID, API_CLAIM)
        .then(res => res.json())
        .then((data) => {
            this.setState({
                partnerDomainList: data
            })
        })
        .catch((e) => {
            console.error(e)
        })
    }

    componentDidMount() {
        this.loadPartnerList()
    }

    render() {
        return(
            <Grid container direction="column" justify="space-around" spacing={3}>
                <Grid item xs={12}>
                    
               </Grid>
           <Grid container direction="row" spacing={3}>
               <Grid item xs={12} sm={12} md={8}>
                <PartnerList
                    partners={this.state.partnerList}
                    onPartnerSelect={this.handlePartnerSelection}
                    selectedPartner={this.state.selectedPartner}
                    loadPartnerLocation={this.loadPartnerLocation}   
                ></PartnerList>
               </Grid>
               <Grid item xs={12} sm={12} md={4}>
                   <PartnerDomainList
                    selectedPartner={this.state.selectedPartner}
                    partnerDomainList={this.state.partnerDomainList}
                    partners={this.state.partnerList}
                   >
                    </PartnerDomainList>
               </Grid>
           </Grid>
           <Grid container direction="column">
                <PartnerDomainEditor
                    selectedPartner={this.state.selectedPartner}
                    validatedDomain={this.state.validatedDomain}
                    partnerDomainList={this.state.partnerDomainList}
                    validationStatus={this.state.validationStatus}
                    onDomainChange={this.handleDomainChange}
                    onValidationChange={this.handleValidationChange}>
                </PartnerDomainEditor>
                <PartnerTrustDeclaration
                    trustedPartnerStatus={this.state.trustedPartnerStatus}
                    onDeclarationChange={this.handleTrustedPartnerStatus}>
                </PartnerTrustDeclaration>
                <PartnerEndorsement
                    selectedPartner={this.state.selectedPartner}
                    selectedPartnerManager={this.state.selectedPartnerManager}
                    trustedPartnerStatus={this.state.trustedPartnerStatus}
                    validatedDomain={this.state.validatedDomain}
                    validationStatus={this.state.validationStatus}
                    onFormSubmission={this.handleFormSubmission}
                    alertText={this.state.alertText}>
                </PartnerEndorsement>
            </Grid>
       </Grid> 
    );
      }
  }

  export default PartnerManagement