import React from 'react';
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { Box, Container, ThemeProvider } from '@material-ui/core';
import Header from './components/Header'
import PartnerManagement from './components/PartnerManagement'
import Endorsement from './components/Endorsement'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const SwissReTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#627D77"
    },
    secondary: {
      main: "#819792"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={SwissReTheme}>
      <Box paddingTop={0}>
        <Header></Header>
        <Container maxWidth="md">
          <Router>
            <Switch>
              <Route path="/endorse/:id" render={(props) => <Endorsement {...props} /> }>
              </Route>
              <Route path="/">
                <PartnerManagement></PartnerManagement>
              </Route>
            </Switch>
          </Router>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
