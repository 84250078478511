import React from 'react'
import { Box, Typography, ListItem, Grid, CircularProgress, ListItemText, Paper, InputBase, IconButton, Input } from '@material-ui/core'
import { FixedSizeList } from 'react-window'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'

const utilizeFocus = () => {
    const ref = React.createRef()
    const setFocus = () => { ref.current &&  ref.current.focus() }
    return { setFocus, ref } 
}

class PartnerList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            searchText: ''
        }

        this.renderRow = this.renderRow.bind(this)
        this.handleFilterSearchClear = this.handleFilterSearchClear.bind(this)
        this.handleFilterSearchChange = this.handleFilterSearchChange.bind(this)
        this.handleSelectPartner = this.handleSelectPartner.bind(this)
        this.inputFocus = utilizeFocus()
        this.handleHoverPartner = this.handleHoverPartner.bind(this)
    }

    componentDidMount() {
        this.inputFocus.setFocus()
    }

    handleFilterSearchClear(e) {
        this.setState({ searchText: '' })
        this.inputFocus.setFocus()
    }

    handleFilterSearchChange(e) {
        this.setState({ searchText: e.target.value })
    }

    handleSelectPartner(selectedPartner) {
        this.props.onPartnerSelect(selectedPartner)
    }

    handleHoverPartner(selectedPartner) {
        if (!selectedPartner.locationSearched) {
            this.props.loadPartnerLocation(selectedPartner)
        }
    }

    renderRow(props) {
        const { data, index, style } = props;

        const selected = data.selected ?  data.selected.mdmPartnerID : null

        return (
          <ListItem
            button
            style={style}
            selected={data.partners[index].mdmPartnerID === selected}
            key={data.partners[index].mdmPartnerID}
            onMouseEnter={() => this.handleHoverPartner(data.partners[index])}
            onClick={() => this.handleSelectPartner(data.partners[index])}
          >
            <ListItemText primary={data.partners[index].partnerName + " (" + data.partners[index].mdmPartnerID +")"} secondary={data.partners[index].location ? data.partners[index].location : " "}/>
          </ListItem> 
        );
    }

    render() {
        var filteredPartners = null

        if (this.props.partners) {
            if (this.state.searchText) {
                const lowerSearch = this.state.searchText.toLowerCase()
                filteredPartners = this.props.partners.filter((partner) =>
                    partner.partnerName.toLowerCase().indexOf(lowerSearch) >= 0
                    || (partner.location && partner.location.toLowerCase().indexOf(lowerSearch) >= 0)
                    || partner.mdmPartnerID.toLowerCase().indexOf(lowerSearch) >= 0
                    )
            } else {
                filteredPartners = this.props.partners
            }
        }

        return (
          <Box>
            <Typography variant="h6">Select Partner Company</Typography>
            <Typography variant="caption">
              Select the company you want to collaborate with.
              <br/>
              <br/>
              You can only register external companies that Swiss Re has a contract or Non-Disclosure-Agreement (NDA) with and the company must be recorded in Swiss Re’s partner management system.
              <br/>
              <br/>
              More information about parter companies can be found in <a href="http://go/mdm" target="_blank" rel="noopener noreferrer">MDM</a>.
              <br/>
              Check this short guide about <a href="https://swissre.sharepoint.com/:b:/r/sites/iam/IAM%20Public/UAO/How_To_find_a_partner_company_in_MDM.pdf" target="_blank" rel="noopener noreferrer">How to find a partner company in MDM</a>.
            </Typography>
            {filteredPartners &&
            <Box>
              <Box paddingTop={1} paddingBottom={1}>
                <Paper className="searchBorder" elevation={1}>
                  <InputBase
                    inputRef={this.inputFocus.ref}
                    autoFocus
                    id="search"
                    variant="outlined"
                    color="secondary"
                    value={this.state.searchText}
                    onChange={this.handleFilterSearchChange}
                    className="searchInput"
                    inputProps={{ 'aria-label': 'Search' }}
                  >
                    <Input ref={this.inputFocus.ref}></Input>
                  </InputBase>
                  {this.state.searchText !== null && this.state.searchText.length > 0 &&
                  <IconButton onClick={this.handleFilterSearchClear} aria-label="clear search">
                    <ClearIcon color="primary" fontSize="large"/>
                  </IconButton>}
                  <SearchIcon color="secondary" fontSize="large"/>
                </Paper>
              </Box>
              {filteredPartners.length > 0 &&
              <Paper>
                <FixedSizeList itemData={{partners: filteredPartners, selected: this.props.selectedPartner}} height={300} itemSize={48} itemCount={filteredPartners.length}>
                  {this.renderRow}
                </FixedSizeList>
              </Paper>}
              {filteredPartners.length === 0 &&
              <Paper elevation={0}>
                <Typography variant="caption">
                  Partner company not found.
                  <br/>
                  You need to request to have the partner company added to one of Swiss Re's partner management systems:
                  <ul>
                    <li>Reinsurance: Please create the Partner record in <a href="https://rih.swissreapps.com" target="_blank" rel="noopener noreferrer">RICH</a> or reach out
                    to your business services team (BST) for support. To find your appropriate BST, go to RICH ADMINISTRATION tab &gt; Team Lists &gt;
                    <a href="https://rih.swissreapps.com/#xft:" target="_blank" rel="noopener noreferrer">DS Business Service Teams</a>. If you are unable to find the required
                    person or have questions, please contact <a href="mailto:PartnerDataManagement_Reinsurance@swissre.com">PartnerDataManagement_Reinsurance@swissre.com</a>.
                    To order RICH access <a href="https://shp.swissre.com/sites/richuserwiki/_layouts/15/start.aspx#/SitePages/Access%20Roles.aspx" target="_blank" rel="noopener noreferrer">click here</a>.</li>
                    <li>Corporate Solutions: Please create the partner in <a href="https://cspartint.swissreapps.com/corpart" target="_blank" rel="noopener noreferrer">CorPart</a>.
                    For questions, please contact the <a href="mailto:PartnerDataManagement_CorSo@swissre.com">PartnerDataManagement_CorSo@swissre.com</a></li>
                    <li>Vendors/Suppliers: Please contact <a href="mailto:Sourcing_VendorSetup@swissre.com">Sourcing_VendorSetup@swissre.com</a> with a description of your use case.</li>
                    <li>iptiQ: Please use the Request support option on <a href="https://swissre.service-now.com/ContactOne/content.do?p=58#step-2" target="_blank" rel="noopener noreferrer">ContactOne</a>.</li>
                  </ul>
                </Typography>
              </Paper>}
            </Box>}
            {!filteredPartners &&
            <Grid container spacing={3} alignItems="center" alignContent="stretch" justify="center">
              <Grid item xs={12} align="center">
                <Box  paddingTop={3}>
                  <Typography color="secondary">Loading Partner List. This process can take some time. Please wait.</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}  align="center">
                <CircularProgress/>
              </Grid>
            </Grid>}
          </Box>
        );
    }
}

export default PartnerList