import React from 'react'
import {Grid, FormControlLabel, Typography, Box, Checkbox, FormGroup} from '@material-ui/core'


class PartnerTrustDeclaration extends React.Component {
    constructor(props) {
        super(props)

        this.onTrustedPartnerChange = this.onTrustedPartnerChange.bind(this)
    }

    onTrustedPartnerChange(e) {
        const name = e.target.name

        const newStatus = this.props.trustedPartnerStatus.map((check) => {
            if (check.name === name) {
                return {name: name, checked: !check.checked}
            } else {
                return check
            }
        })

        this.props.onDeclarationChange(newStatus)
    }

    render() {
      return(
        <Grid container direction="row" justify="space-around">
            <Grid item xs={12}>
                <Typography variant="h6">
                    Declaration of Trusted Partner Status
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box paddingTop={1} paddingBottom={1}>
                    <FormGroup>
                        {this.props.trustedPartnerStatus.map((trustedPartnerCheck) => (
                            <FormControlLabel key={trustedPartnerCheck.name}
                            control={
                                <Checkbox
                                    checked={trustedPartnerCheck.checked}
                                    onChange={this.onTrustedPartnerChange}
                                    name={trustedPartnerCheck.name}
                                    color="primary"
                                ></Checkbox>
                            }
                            label={trustedPartnerCheck.name}
                            ></FormControlLabel>
                        ))}
                    </FormGroup>
                </Box>
            </Grid>
        </Grid>
    );
      }
  }

  export default PartnerTrustDeclaration